import React from 'react';

import { Layout, Link, Resume, SEO, WaveSection } from 'src/components';

const ResumePage = () => (
  <Layout activePage="resume">
    <SEO title="Resume" />
    <Resume>
      <WaveSection disableTopMargin>
        <p>
          This is an overview of my professional background and the products and projects I've been working on the last
          few years. My complete resume can be viewed or downloaded as a pdf by clicking below.
        </p>
        <p>
          <Link to="/andrew-robert-cernek-resume.pdf" external button>
            Download Resume
          </Link>
        </p>
      </WaveSection>
      {/* <Resume.Position name="Genbloq" link="https://genbloq.com/" start="2018">
        <Resume.Role name="Lead Software Engineer" />
        <Resume.Description>
          <b>Healthloq</b>
          <p>Platform to track and share the genealogy of nutraceutical products from seed to shelf</p>
        </Resume.Description>
        <Resume.Description>
          <b>Realbloq</b>
          <p>A permissioned, blockchain-based real estate wire fraud prevention application.</p>
        </Resume.Description>
      </Resume.Position>
      <Resume.Position name="Sweetbridge" link="https://sweetbridge.com/" start="2017" end="2018">
        <Resume.Role name="Senior Software Engineer & Team Lead" />
        <Resume.Description>
          <p>An economic platform designed to unlock value in assets, commerce, and supply chains.</p>
        </Resume.Description>
      </Resume.Position>
      <Resume.Position name="StackTrends" link="https://www.lashfancy.com/">
        <Resume.Role name="Technical Founder" />
        <Resume.Description>
          <p>A website that tracks and curates trends in software engineering technologies.</p>
        </Resume.Description>
      </Resume.Position>
      <Resume.Position name="Really Simple" start="2016" end="2017">
        <Resume.Role name="Senior Software Developer" />
        <Resume.Description>
          <p>An apparel marketplace using AI technologies to streamline the customer experience.</p>
        </Resume.Description>
      </Resume.Position>
      <Resume.Position name="SpyFu" link="https://www.spyfu.com/" start="2012" end="2015">
        <Resume.Role name="Project Manager & Software Developer" start="2014" end="2015" />
        <Resume.Role name="Junior Software Developer" start="2013" end="2014" />
        <Resume.Role name="Front End Developer" start="2012" end="2013" />
        <Resume.Description>
          <p>A suite of analytics, tracking, and reporting tools designed for search marketers.</p>
        </Resume.Description>
      </Resume.Position>
      <Resume.Position name="Education" subtext="Northern Arizona University">
        <Resume.Role name="Bachelor of Arts, Religious Studies" start="2000" end="2005" />
      </Resume.Position> */}
    </Resume>
  </Layout>
);

export default ResumePage;
